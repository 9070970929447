import React, {useState} from "react";
import TrashImage from "../../assets/images/admin/trash.svg"
import Language from "../../helpers/language";
import {Drip7Button} from "../drip7_button";
import { Drip7Input } from "../drip7_input";

export const ContentVideoWidget = props => {
    const { access, question, content, showToast, onChange, onFile } = props;

    const [video_state, setVideoState] = useState({
        filename: "",
        raw_file: null
    });

    const [cc_state, setCcState] = useState({
        filename: "",
        raw_file: null
    });

    const question_uid = question.uid || "";
    const video = content.video || "";
    const alt_text = content.alt_text || "";
    const text = content.text || ""

    //File ref required to access the file browser
    const fileVideoRef = React.useRef();
    const fileCcRef = React.useRef();

    // CC files need to come from the same domain as the app,
    //  otherwise chrome/edge/firefox blocks it
    const fixCC = (url) => {
        if (url && url.indexOf('/') >= 0) {
            //Get the filename, but drop the extension
            const file = url.split('/').pop().split('.')[0];
            return '/get_cc/' + file;
        }
        else {
            return '';
        }
    }

    //Funky pass through to open the file browse
    const handleVideoFileClick = () => {
        if ( !access.write ) {
            return
        }

        fileVideoRef.current.click();
    };

    //Funky pass through to open the file browse
    const handleCcFileClick = () => {
        if ( !access.write ) {
            return
        }

        fileCcRef.current.click();
    };

    //We have a selected file
    const handleVideoFileChange = e => {
        if ( !access.write ) {
            return
        }

        const raw_file = e.target.files[0];
        onFile( 'video', raw_file )
        setVideoState({ ...video_state, filename: raw_file.name, raw_file });
    };

    //We have a selected file
    const handleCcFileChange = e => {
        if ( !access.write ) {
            return
        }

        const raw_file = e.target.files[0];
        onFile( 'closed_caption', raw_file )
        setCcState({ ...cc_state, filename: raw_file.name, raw_file });
    };

    const handleRemoveVideo = e => {
        if ( !access.write) {
            return
        }

        onChange('content', { ...content, video: null})
    }

    const handleChange = e => {
        if ( !access.write ) {
            return
        }

        onChange('content', { ...content, [e.target.id]: e.target.value });
    };

    return (<div
            className='basic-modal__field-container--add-question basic-modal__add-question--image px-1'>
            {video ? (<>
                    <video className='daily-questions__video'
                           title={content.alt_text}
                           controls
                           controlsList='nodownload'>
                        <source src={content.video}/>
                        {Language.getSentenceCase( 'Your browser doesn\'t support video tags' )}.
                        <track label="English"
                               kind="subtitles"
                               srcLang="en"
                               src={fixCC( content.closed_caption )}
                               default/>
                    </video>
                    <div className='delete-user'
                         onClick={handleRemoveVideo}>
                        <img src={TrashImage} alt='trash image'/>
                        {Language.getTitleCase( 'remove video' )}
                    </div>
                </>) : (<div className='basic-modal__add-video-container'
                             onClick={handleVideoFileClick}>
                    <svg
                        className='basic-modal__add-image-icon'
                        width='45'
                        height='34'
                        viewBox='0 0 45 34'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            opacity='0.2'
                            d='M40.7812 34C43.0664 34 45 32.1543 45 29.7812V4.46875C45 2.18359 43.0664 0.25 40.7812 0.25H4.21875C1.8457 0.25 0 2.18359 0 4.46875V29.7812C0 32.1543 1.8457 34 4.21875 34H40.7812ZM9.84375 5.17188C12.4805 5.17188 14.7656 7.45703 14.7656 10.0938C14.7656 12.8184 12.4805 15.0156 9.84375 15.0156C7.11914 15.0156 4.92188 12.8184 4.92188 10.0938C4.92188 7.45703 7.11914 5.17188 9.84375 5.17188ZM5.625 28.375V24.1562L11.8652 17.916C12.3047 17.4766 12.9199 17.4766 13.3594 17.916L18.2812 22.75L30.1465 10.8848C30.5859 10.4453 31.2012 10.4453 31.6406 10.8848L39.375 18.5312V28.375H5.625Z'
                            fill='#607D8B'
                        />
                    </svg>

                    <p className='basic-modal__upload-label'>
                        <span>{Language.getSentenceCase( 'No video file selected' )}</span>
                    </p>
                </div>)}

            <div
                className='basic-modal__wrapper basic-modal__wrapper--add-badge'>
                <div className="video-input-wrap">
                    <label className='basic-modal__label' htmlFor='text'>
                        {Language.getTitleCase( 'Video' )}
                    </label>
                    <input
                        type='file'
                        accept='video/*'
                        ref={node => (fileVideoRef.current = node)}
                        readOnly={ !access.write}
                        onChange={handleVideoFileChange}
                        style={{ display: "none" }}
                    />
                    <div className='video-input'>
                        <input
                            className='basic-modal__input rounded-md border-gray-300 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            type='text'
                            readOnly
                            name='user-file'
                            placeholder={Language.getSentenceCase( 'No File Selected' )}
                            value={video_state.filename}
                        />
                        <Drip7Button
                            disabled={ !access.write}
                            nameBtn='choose-file'
                            onClick={handleVideoFileClick}>
                            {Language.getTitleCase( 'Choose video' )}
                        </Drip7Button>
                    </div>
                </div>
            </div>

            <div
                className='basic-modal__wrapper basic-modal__wrapper--add-badge'>
                <div className="video-input-wrap">
                    <label className='basic-modal__label' htmlFor='text'>
                        {Language.getTitleCase( 'closed caption' )}
                    </label>
                    <input
                        type='file'
                        accept='.vtt'
                        ref={node => (fileCcRef.current = node)}
                        readOnly={ !access.write}
                        onChange={handleCcFileChange}
                        style={{ display: "none" }}
                    />
                    <div className='video-input'>
                        <input
                            className='basic-modal__input rounded-md border-gray-300 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            type='text'
                            readOnly
                            name='user-file'
                            placeholder={Language.getSentenceCase( 'No Closed Caption Selected' )}
                            value={cc_state.filename}
                        />
                        <Drip7Button
                            disabled={ !access.write}
                            nameBtn='choose-file'
                            onClick={handleCcFileClick}>
                            {Language.getTitleCase( 'Choose caption' )}
                        </Drip7Button>
                    </div>
                </div>
            </div>

            <div className='basic-modal__field--add-question width-100 mt-3'>
                <label className='basic-modal__label' htmlFor='text'>
                    {Language.getTitleCase( 'Alt Text' )}
                </label>
                <Drip7Input
                    className="mt-2"
                    placeholder={Language.getSentenceCase( 'Alt text for image' )}
                    id='alt_text'
                    readOnly={ !access.write}
                    value={alt_text}
                    onChange={handleChange}
                />
            </div>

            <div className='basic-modal__field--add-question width-100'>
                <label className='basic-modal__label' htmlFor='text'>
                    {Language.getTitleCase( 'Text' )}
                </label>
                <textarea
                    className='basic-modal__input basic-modal__input--add-question width-100 border-0 ring-1 ring-gray-300 focus:ring-drip7'
                    placeholder={Language.getSentenceCase( 'Type question text here' ) + '...'}
                    id='text'
                    readOnly={ !access.write}
                    onChange={handleChange}
                    value={text}></textarea>
            </div>
        </div>);
};
