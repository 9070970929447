import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { useMsal } from "@azure/msal-react";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import SSO from "../../helpers/sso"
import { Drip7OriginalModal } from "../../modals/drip7_original_modal";
import { UserModal } from "../../modals/admin/user_modal";
import { SSOEmailUnregisteredModal } from "../../modals/admin/sso_email_unregistered_modal"
import { AddUsersFromFileModal } from "../../modals/admin/add_users_from_file_modal";
import { UserPointsModal } from "../../modals/admin/user_points_modal";

import { ConfirmModal } from "../../modals/confirm_modal";
import { AvatarModal } from "../../modals/avatar_modal";
import { AuthenticationType, MemberRole } from "../../helpers/consts";
import { Drip7Button } from "../../components/drip7_button";
import {Drip7Modal} from "../../modals/drip7_modal";

//State wrapper
const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    return <BaseComponent {...props} usr_info={usr_info} msal={useMsal()} />;
};

//Define my view compon
class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            users_selected: {},
            avatars: [],
            sso_send_list: [],
            modal_info: null,
            search: "",
            sort_by: "",
            sort_by_reverse: false,
            show_add_user_file: false,
            show_create_user: false,
            show_sso_unregistered: false,
            show_avatar: false,
            show_points: false,
            show_bulk_points: false,
            confirm: null
        };

        this.updateUserList = this.updateUserList.bind(this);

        this.handleExportXlsx = this.handleExportXlsx.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectUser = this.handleSelectUser.bind(this);
        this.handleDisableUser = this.handleDisableUser.bind(this);
        this.handleRemoveUser = this.handleRemoveUser.bind(this);
        // this.handleEmailSSOUsers = this.handleEmailSSOUsers.bind(this);
        this.sendSSOEmails = this.sendSSOEmails.bind(this);
        this.handleSortBy = this.handleSortBy.bind(this);
        this.handleViewUser = this.handleViewUser.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleAddUserfile = this.handleAddUserfile.bind(this);
        this.handleShowCreate = this.handleShowCreate.bind(this);
    }

    componentDidMount() {
        this.updateUserList( false );

        //Get a list of all users for this tenant
        Util.fetch_js( "/avatar/list/", {},
            js => {
                this.setState({ avatars: js.avatars })
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tenant_uid != this.props.tenant_uid) {
            this.updateUserList( true );
        }
    }

    filterUsers(search, users) {
        //Do nothing
        if (search == "") {
            return users;
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            if (
                user.name.toLowerCase().indexOf(search_lc) >= 0 ||
                user.nickname.toLowerCase().indexOf(search_lc) >= 0 ||
                user.email.toLowerCase().indexOf(search_lc) >= 0 ||
                user.groups.toLowerCase().indexOf(search_lc) >= 0 ||
                user.tenant.toLowerCase().indexOf(search_lc) >= 0 ||
                user.role.toLowerCase().indexOf(search_lc) >= 0
            ) {
                result.push(user);
            }
        }

        return result;
    }

    updateUserList( detailed ) {
        const { tenant_uid, showToast } = this.props;

        //Get a list of all users for this tenant
        Util.fetch_js( "/human/list/", { tenant_uid, detailed },
            js => {
                //Reload with detailed view
                if ( !detailed ) {
                    this.updateUserList( true )
                }

                this.setState({ users: js.users })
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    handleExportXlsx() {
        const { tenant_uid, showToast } = this.props;
        const { search, users } = this.state;

        let payload = {
            tenant_uid: tenant_uid
        };
        if (search || users) {
            let filtered_users = this.filterUsers(search, users);
            payload.uids = filtered_users.map(u => u.uid);
        }

        Util.postDataAndOpenNewTab('/human/export_members/', payload);

        /*
        //Export a user list, and then redirect to it
        Util.fetch_js( "/human/export_members/", payload,
            js => {
                window.open(js.url, '_blank')
                //window.location.href = js.url
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        );
         */
    }

    // handleEmailSSOUsers() {
    //     const { showToast, usr_info } = this.props;
    //     const { instance, accounts } = this.props.msal;
    //     const { users } = this.state;

    //     let emails = []
    //     SSO.getAllUserEmails(instance, accounts, (sso_users) => {
    //         emails = emails.concat(sso_users.filter((e) => users.find((u) => u.email.localeCompare(e) == 0) == undefined))
    //         emails.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    //         this.setState({...this.state, sso_send_list: emails, show_sso_unregistered: true});
    //     },
    //     null,
    //     (errmsg) =>  { showToast('Fetch user emails: ' + errmsg, 'failure'); })
    // }

    sendSSOEmails (send_list) {
        const { tenant_uid, showToast } = this.props;

        let payload = {
            tenant_uid: tenant_uid,
            emails: send_list
        };

        //Export a user list, and then redirect to it
        Util.fetch_js( "/human/email_unregistered_users/", payload,
            js => {
                showToast(`${Language.getString('sent')} ${js.count} ${Language.getString('email(s)')}`, 'successful');
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        );
    }

    handleDisableUser() {
        const { showToast } = this.props;
        let { users_selected, users } = this.state;
        let users_submitted = {};

        if (Object.keys(users_selected) == 0) return;

        let disable = true
        users.forEach( u => {
            if ( users_selected[u.uid] && u.disabled ) {
                disable = false
            }
        })

        Util.fetch_js( "/human/disable/", { user_uids: Object.keys(users_selected), disable },
            js => {
                let c = 0;
                Object.keys(users_selected).forEach(key => {
                    let m = users.find(m => m.uid == key);
                    m.disabled = !m.disabled;
                    c = c + 1;
                });
                showToast(Language.getSentenceCase('modified') + " " + c + " " + Language.getString('users'), 'failure');
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
        this.setState({ ...this.state, confirm: null });
    }

    handleRemoveUser() {
        const { showToast } = this.props;
        let { users_selected, users } = this.state;
        let users_submitted = {};

        if (Object.keys(users_selected) == 0) return;

        Object.keys(users_selected).forEach(key => {
            if (users_selected[key] == true) {
                let m = users.find(m => m.uid == key);
                users_submitted[m.uid] = m.deleted_on == 0;
            }
        });

        Util.fetch_js( "/human/remove/", { user_uids: users_submitted, remove: true },
            js => {
                let c = 0;
                Object.keys(users_submitted).forEach(key => {
                    let m = users.find(m => m.uid == key);
                    m.deleted_on = users_submitted[m.uid] ? js.deleted_on : 0;
                    c = c + 1;
                });
                //Clear out the users selected
                this.setState({ ...this.state, users_selected: {} });
                this.updateUserList( true );
                showToast(Language.getSentenceCase('removed') + " " + c + " " + Language.getString('users'), 'successful');
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
        this.setState({ ...this.state, confirm: null });
    }

    handlePermanentRemove() {
        const { showToast } = this.props;
        let { users_selected, users } = this.state;
        let users_submitted = {};

        if (Object.keys(users_selected) == 0) return;

        Object.keys(user_selected).forEach(key => {
            if (users_selected[key] == true) {
                let m = users.find(m => m.uid == key);
                users_submitted[m.uid] = m.deleted_on == 0;
            }
        });

        Util.fetch_js( "/human/permanent_remove/", { user_uids: users_submitted },
            js => {
                let c = 0;
                Object.keys(usets_submitted).forEach(key => {
                    let m = users.find(m => m.uid == key);
                    m.deleted_on = users_submitted[m.uid] ? js.deleted_on : 0;
                    c = c + 1;
                });
                showToast(Language.getSentenceCase('permanently deleted') + " " + c + " " + Language.getString('users'), 'failure');
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
        this.setState({ ...this.state, confirm: null });
    }

    handleSelectAll(checked) {
        const { search, users } = this.state;
        let users_selected = {};

        //Should we check or send empty list?
        if (checked) {
            //Only select what the user can see
            let filtered_users = this.filterUsers(search, users);

            //Check all those items
            filtered_users.forEach( u => {
                users_selected[u.uid] = true;
            })
        }

        this.setState({ users_selected });
    }

    handleSelectUser(checked, uid) {
        let { users_selected } = this.state;

        //update my list
        if (checked) {
            users_selected[uid] = true;
        }
        else {
            delete users_selected[uid];
        }

        this.setState({ users_selected });
    }

    handleSortBy(code) {
        const { sort_by, sort_by_reverse } = this.state;
        const sbr = sort_by == code ? !sort_by_reverse : false;

        this.setState({ sort_by: code, sort_by_reverse: sbr });
    }

    handleViewUser(modal_info) {
        this.setState({ modal_info });
    }

    handleModalClose() {
        this.updateUserList( true );
        this.setState({
            modal_info: null,
            show_add_user_file: false,
            show_create_user: false,
            show_sso_unregistered: false,
            show_avatar: false,
            show_points: false,
            show_bulk_points: false,
        });
    }

    handleAddUserfile() {
        this.setState({ show_add_user_file: true });
    }

    handleShowCreate() {
        this.setState({ show_create_user: true });
    }

    disableLabels() {
        const { users_selected, users } = this.state;

        let disable = true
        users.forEach( u => {
            if ( users_selected[u.uid] && u.disabled ) {
                disable = false
            }
        })

        if ( !disable ) {
            return { button: Language.getTitleCase('enable selected user'), confirm: Language.getTitleCase('enable') };
        }
        else {
            return { button: Language.getTitleCase('disable selected user'), confirm: Language.getTitleCase('disable') };
        }
    }

    removeLabels() {
        const { users_selected, users } = this.state;
        let removemsg = 0;
        //1: show msg restore, 2: show msg remove, 3: show both
        Object.keys(users_selected).forEach(k => {
            const m = users.find(m => m.uid == k);
            removemsg = m.deleted_on != 0 ? removemsg | 1 : removemsg | 2;
        });
        let removeButton = "";
        let confirmRemoveButton = "";
        switch (removemsg) {
            case 1:
                removeButton = Language.getTitleCase('Restore Selected User');
                confirmRemoveButton = Language.getTitleCase('restore');
                break;
            case 2:
                removeButton = Language.getTitleCase('remove selected user');
                confirmRemoveButton = Language.getTitleCase('remove');
                break;
            case 3:
                removeButton = Language.getTitleCase('Restore/Remove Selected User');
                confirmRemoveButton = Language.getTitleCase('Restore/Remove');
                break;
        }
        return { button: removeButton, confirm: confirmRemoveButton };
    }

    render() {
        const { tenants, tenant_uid } = this.props;
        const { showToast } = this.props;
        const {
            users,
            users_selected,
            avatars,
            sso_send_list,
            search,
            sort_by,
            sort_by_reverse,
            modal_info,
            show_add_user_file,
            show_create_user,
            show_sso_unregistered,
            show_avatar,
            show_points,
            show_bulk_points,
            confirm
        } = this.state;
        const selected_count = Object.keys(users_selected).length;

        let disable = this.disableLabels();
        let remove = this.removeLabels();
        if (selected_count > 1) {
            disable.button = disable.button + "s";
            remove.button = remove.button + "s";
        }

        const tenant = tenants.find( x => x.uid == tenant_uid )
        let filtered_users = this.filterUsers(search, users);

        //Sort?
        if (sort_by != "") {
            if (!sort_by_reverse) {
                filtered_users.sort((a, b) =>
                    a[sort_by]
                        .toLowerCase()
                        .localeCompare(b[sort_by].toLowerCase())
                );
            }
            else {
                filtered_users.sort((a, b) =>
                    b[sort_by]
                        .toLowerCase()
                        .localeCompare(a[sort_by].toLowerCase())
                );
            }
        }

        const confirmAction = action => {
            let yes = null;
            let msg = null;
            if (action == this.handleRemoveUser) {
                yes = remove.confirm;
                msg = remove.button + "?";
            }
            else if (action == this.handleDisableUser) {
                yes = disable.confirm;
                msg = disable.button + "?";
            }
            else {
                throw Language.getSentenceCase('Program error, Unknown action') + ": " + action;
            }

            //Setting a confirm action, will load message box, and if accepted saves
            const new_confirm = { action, msg, yes, no: Language.getTitleCase('cancel') };
            this.setState({ ...this.state, confirm: new_confirm });
        };

        const avatar_image = (avatars.length > 0)? avatars[0].image: ""

        return (
            <>
                <div className='users-list'>
                    <div className='central__head'>
                        <div className='central__head-search-wrapper'>
                            <svg
                                width='15'
                                height='16'
                                viewBox='0 0 15 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                    fill='currentColor'></path>
                            </svg>
                            <input
                              className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                              type='search'
                              name='search'
                              placeholder={Language.getSentenceCase('Search by name, email') +'...'}
                              aria-label={Language.getSentenceCase('Search by name, email') +'...'}
                              value={search}
                              onChange={e =>
                                  this.setState({ search: e.target.value })
                              }
                            />
                        </div>

                        <div className='central__head-button-container'>
                            <Drip7Button
                                nameBtn='export'
                                onClick={this.handleExportXlsx}>
                                {Language.getTitleCase( 'export as xlsx' )}
                            </Drip7Button>
                            <Drip7Button
                                nameBtn='create-user'
                                onClick={this.handleShowCreate}>
                                {Language.getTitleCase('create new user')}
                            </Drip7Button>
                            <Drip7Button
                                nameBtn='add-user'
                                onClick={this.handleAddUserfile}>
                                {Language.getTitleCase('add users from file')}
                            </Drip7Button>
                        </div>
                    </div>

                    {selected_count <= 0 && (
                        <div className='default-users-selectors'>
                            <div className='default-users-selectors-wrap'>
                                <div className='selectors'>
                                    <br />
                                </div>
                            </div>
                        </div>
                    )}

                    {selected_count > 0 && (
                        <div className='selected-users-buttons'>
                            <div className='selected-users-buttons-wrap'>
                                {false &&
                                    <Drip7Button
                                        name='adust-points'
                                        onClick={() => this.setState({show_bulk_points: true})}>
                                        {Language.getTitleCase("adjust points")}
                                    </Drip7Button>
                                }
                                <Drip7Button
                                    mode='outlined'
                                    color='red-500'
                                    nameBtn='disable-user'
                                    onClick={() =>
                                        confirmAction(this.handleDisableUser)
                                    }>
                                    {disable.button}
                                </Drip7Button>
                                <Drip7Button
                                    nameBtn='remove-user'
                                    color='red-500'
                                    onClick={() =>
                                        confirmAction(this.handleRemoveUser)
                                    }>
                                    {remove.button}
                                </Drip7Button>
                            </div>
                        </div>
                    )}

                    <div className='table-container'>
                        {confirm != null && (
                            <ConfirmModal
                                msg={confirm.msg}
                                yesTxt={confirm.yes}
                                noTxt={confirm.no}
                                onYes={() => confirm.action(true)}
                                onNo={() =>
                                    this.setState({
                                        ...this.state,
                                        confirm: null
                                    })
                                }
                            />
                        )}
                        <table
                            className='users-table'
                            role='region'
                            aria-live='polite'>
                            <tbody>
                                <tr className='table-headings'>
                                    <th className='select-all-col'>
                                        <input
                                            aria-label="checkbox"
                                            type='checkbox'
                                            name='sel-all'
                                            className='sel-all ml-4'
                                            onChange={event => this.handleSelectAll( event.target.checked ) }
                                        />
                                        <span className='dash ml-4'></span>
                                    </th>
                                    <th className='user-photo-col'></th>
                                    <th className='user-name-col' id='user_name' onClick={() => this.handleSortBy("name") }>
                                        {Language.getTitleCase('user name') + " "}
                                        <i className='sort-button'></i>
                                    </th>
                                    <th className='user-name-col' id='user_nick_name' onClick={() => this.handleSortBy("nickname") }>
                                        {Language.getTitleCase('nickname') + " "}
                                        <i className='sort-button'></i>
                                    </th>
                                    <th className='email-col' id='user_email' onClick={() => this.handleSortBy("email") }>
                                        {Language.getTitleCase('email')}<i className='sort-button'></i>
                                    </th>
                                    {false && <th
                                        className='progress-col'
                                        id='tenant'
                                        onClick={() =>
                                            this.handleSortBy("tenant")
                                        }>
                                        {Language.getTitleCase('tenant')}<i className='sort-button'></i>
                                    </th> }
                                    <th className='access-level-col' id='access_level' onClick={() => this.handleSortBy("role") }>
                                        {Language.getTitleCase('access level')}
                                        <i className='sort-button'></i>
                                    </th>
                                    <th className='activity-col' id='activity' onClick={() => this.handleSortBy("activity") }>
                                        {Language.getTitleCase('activity')}<i className='sort-button'></i>
                                    </th>
                                    <th className='dept-col' onClick={() => this.handleSortBy("groups") }>
                                        {Language.getTitleCase('groups')}<i className='sort-button'></i>
                                    </th>
                                    <th className='more-col'></th>
                                </tr>
                                {Object.entries(filtered_users).map(
                                    ([key, user]) => (
                                        <tr
                                            className='user-table-info'
                                            key={"user_tr_" + key}>
                                            <td className='user-table-select'>
                                                <input
                                                    type='checkbox'
                                                    aria-label="checkbox"
                                                    onChange={event => this.handleSelectUser( event.target.checked, user.uid ) }
                                                    checked={ user.uid in users_selected }
                                                    className="ml-4"
                                                />
                                                <span className='checkmark ml-4'></span>
                                            </td>
                                            <td className='user-table-photo'
                                                onClick={() => this.handleViewUser(user) }>
                                                <i className='user-profile-photo'></i>
                                            </td>
                                            <td className='user-table-name'
                                                header='user_name'
                                                onClick={() => this.handleViewUser(user) }>
                                                {user.name}
                                            </td>
                                            <td className='user-table-name'
                                                header='user_name'
                                                onClick={() => this.handleViewUser(user) }>
                                                {user.nickname}
                                            </td>
                                            <td className='user-table-email'>
                                                <a href='mailto:{user.email}' aria-label="send mail to listed recipient">
                                                    {user.email}
                                                </a>
                                            </td>
                                            {false && <td
                                                className='user-table-progress'
                                                header='tenant'
                                                onClick={() => this.handleViewUser(user) }>
                                                {user.tenant}
                                            </td> }
                                            <td
                                                className='user-table-access'
                                                onClick={() => this.handleViewUser(user) }>
                                                {user.role}
                                            </td>
                                            <td
                                                className='user-table-activity'
                                                onClick={() => this.handleViewUser(user) }>
                                                {!user.disabled ? user.activity : Language.getTitleCase("Disabled")}
                                            </td>

                                            <td
                                                className='user-table-dept'
                                                onClick={() => this.handleViewUser(user) }>
                                                {user.groups}
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <Drip7Modal
                    show={modal_info != null}
                    className='sm:max-w-4xl'
                    onClose={() => this.handleModalClose()}>
                    <UserModal
                        info={modal_info}
                        tenant_uid={tenant_uid}
                        tenants={tenants}
                        showToast={showToast}
                        onClose={this.handleModalClose}
                        onAvatar={() => this.setState({ show_avatar: modal_info })}
                        onShowPoints={() => this.setState({ show_points: modal_info })}
                    />
                </Drip7Modal>

                <Drip7Modal
                    show={show_create_user}
                    className='sm:max-w-4xl'
                    onClose={() => this.handleModalClose()}>
                    <UserModal
                        info={{ profile_url: avatar_image, tenant, groups: "", role: MemberRole.NORMAL, name: '', nickname: '', email: '', notify: 'Daily' }}
                        tenant_uid={tenant_uid}
                        tenants={tenants}
                        showToast={showToast}
                        onClose={() => this.handleModalClose()}
                        onAvatar={() => this.setState({ show_avatar: modal_info })}
                        onShowPoints={() => this.setState({ show_points: modal_info })}
                    />
                </Drip7Modal>

                <Drip7OriginalModal>
                    {show_add_user_file && (
                        <AddUsersFromFileModal
                            tenant_uid={tenant_uid}
                            showToast={showToast}
                            onClose={this.handleModalClose}
                        />
                    )}

                    {false && show_create_user && (
                        <UserModal
                            info={{ profile_url: avatar_image, tenant, groups: "", role: MemberRole.NORMAL, name: '', nickname: '', email: '', notify: 'Daily' }}
                            tenant_uid={tenant_uid}
                            tenants={tenants}
                            showToast={showToast}
                            onClose={this.handleModalClose}
                        />
                    )}

                    {show_sso_unregistered && (
                        <SSOEmailUnregisteredModal 
                            emaillist={sso_send_list} 
                            onSend={this.sendSSOEmails}
                            onClose={this.handleModalClose}
                        />
                    )}

                    {show_avatar && (
                        <AvatarModal
                            user_uid={show_avatar.uid}
                            profile_url={show_avatar.profile_url}
                            showToast={showToast}
                            onChange={this.handleChange}
                            onClose={this.handleModalClose}
                        />
                    )}

                    {show_points && (
                        <UserPointsModal
                            info={show_points}
                            showToast={showToast}
                            onClose={this.handleModalClose}
                        />
                    )}
                </Drip7OriginalModal>
            </>
        );
    }
}

export const UsersTab = withStore(Klass);
