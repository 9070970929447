export const WS_URL = 'wss://v2.drip7.com:8080'
export const WS_MAX_SIZE = 1048576
export const SENTRY_DSN =  "https://7209d48aa10568b6a135922b6985d323@o4506077940350976.ingest.sentry.io/4506079526191104"
export const SENTRY_ENV = "Production"

export const HOST = "https://play.drip7.com"

export const STRIPE_PK = "pk_live_51IgXVuCEF79w8UWn0MpBMQWmpivRoXsGMk3kBdReXJj7Sbi3ohALIeHnRBnW8b5CWsDxP69d91z42xR40mg3SMJO00gqQ6YqCH"
export const STRIPE_URL = "https://play.drip7.com/onboard_complete"


