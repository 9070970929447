import React, {useEffect, useState} from "react";
import Language from "../../helpers/language";

export const AnswerFillWidget = props => {
    const { access, onChange } = props;
    const [state, setState] = useState({});

    let correct = props.answer.correct || [""];

    //Hack as we switch from using answers to correct
    if ( !Array.isArray(correct) ) {
        correct = [correct]
    }

    useEffect(() => {
        onChange('answer', {}, { correct });
    }, [])

    const handleCorrect = (correct) => {
        if ( !access.write ) {
            return
        }

        onChange('answer', { correct: correct.split(/\s*[\n;,]\s*/) })
    }

    return (
        <textarea
            className='basic-modal__input basic-modal__input--add-question width-100 border-0 ring-1 ring-gray-300 focus:ring-drip7 text-base'
            rows={3}
            placeholder={Language.getTitleCase("Answer")}
            value={correct.join('\n')}
            readOnly={!access.write}
            onChange={e => handleCorrect( e.target.value )}
        />
    );
};
