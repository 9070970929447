import React, { useState } from "react";
import Language from "../../helpers/language";

export const ExplanationTextWidget = props => {
    const { access, explanation, onChange } = props;
    const info = explanation.info || ""

    const handleChange = e => {
        onChange('explanation', { ...explanation, [e.target.id]: e.target.value })
    };

    return (
        <div className='basic-modal__field--add-question width-100'>
            <label className='basic-modal__label' htmlFor='text'>
                {Language.getTitleCase('Explanation')}
            </label>
            <textarea
                className='basic-modal__input basic-modal__input--add-question width-100 border-0 ring-1 ring-gray-300 focus:ring-drip7 text-base'
                placeholder={Language.getSentenceCase('type explanation here')+'...'}
                id='info'
                readOnly={!access.write}
                onChange={handleChange}
                value={info}></textarea>
        </div>
    );
};
